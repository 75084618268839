<template>
  <div>
    <v-container>
      <div class="text-h2">Mundo Ingeniería UdeC</div>
      <div class="text-subtitle-1">
        Conoce de qué se trata la vida del ingeniero en la UdeC
      </div>
      <v-divider class="my-6"></v-divider>

      <div>Carreras > carrera</div>
      <derivaciones-home :v="'mundo-ingenieria'"></derivaciones-home>
    </v-container>
  </div>
</template>
<script>
import DerivacionesHome from "../../components/info/DerivacionesHome.vue";
export default {
  components: { DerivacionesHome },
  beforeRouteEnter(to, from, next) {
    next((cpt) => {
      if (cpt.lanzamiento) {
        next();
      } else {
        next("/");
      }
    });
  },
};
</script>
<style lang="scss">
</style>