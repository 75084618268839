<template lang="">
    <v-container>
        <div class="text-h1">Registro</div>
        <v-divider class="my-6"></v-divider>
        <v-responsive :aspect-ratio='10/8' v-on:submit.prevent="submitForm">
            <v-container class="pa-10 my-10 rounded-lg grey lighten-4" id="form-content">
                <div class='text-h2 font-weight-bold'>Registro</div>
                <v-divider class="my-5"></v-divider>
                <!-- {{formData}} -->
                <div>
                    <div v-for="(mensaje, indexmensaje) in mensajesFormulario" 
                    :key="indexmensaje" >
                      <v-alert 
                      v-if="mensaje.estado" 
                      :type="mensaje.type">
                          {{mensaje.text}}
                      </v-alert>
                    </div>
                    <v-form v-model="formularioValido" class="py-5">
                        
                        <div class="d-flex mx-n1">
                          <v-text-field
                              class="mx-1"
                              label="Nombre"
                              :type="'text'"
                              :append-icon="'mdi-account'"
                              counter
                              :rules="[reglas.required]"
                              v-model="formData.first_name"
                              outlined
                              dense
                              color="blue-grey"
                          ></v-text-field>
                          <v-text-field
                              class="mx-1"
                              label="Apellidos"
                              :type="'text'"
                              :append-icon="'mdi-account'"
                              counter
                              :rules="[reglas.required]"
                              v-model="formData.last_name"
                              outlined
                              dense
                              color="blue-grey"
                          ></v-text-field>
                        </div>

                        <v-text-field
                            label="Correo electrónico"
                            outlined
                            dense
                            name="correo"
                            v-model="formData.usuario"
                            :rules="[reglas.required, reglas.email]"
                            color="blue-grey"
                        ></v-text-field>

                        <v-text-field
                              class="mx-1"
                              label="Rut"
                              :type="'text'"
                              :append-icon="'mdi-lock'"
                              counter
                              :rules="[reglas.rut]"
                              v-model="formData.rut"
                              hint="Utiliza el formato xxxxxxxx-x"
                              outlined
                              dense
                              color="blue-grey"
                        ></v-text-field>

                        <div class="mb-4">
                          
                          <div class="text-h4 mb-3">Selecciona tus hobbies e intereses cotidianos 🤩🌋🦉🚴‍♀️🧙‍♂️</div>
                          <div class="text-body-2 mb-3 blue-grey--text text--lighten-1">Máximo 10 - {{formData.hobbies.length}}/10</div>
                          <v-chip-group v-model="formData.hobbies" column multiple max="10">
                            <v-chip filter-icon="mdi-heart" active-class="red accent-3 red--text text--accent-3" v-for="(hobbie, index) in hobbies" :key="index" filter outlined>
                              {{hobbie.label}}
                            </v-chip>
                          </v-chip-group>
                        </div>
                        <div class="mb-4">
                          
                          <div class="text-h4 mb-3">Selecciona tus intereses tecnológicos 👽🚀👩‍🚀👾🐱‍🚀</div>
                          <div class="text-body-2 mb-3 blue-grey--text text--lighten-1">Máximo 10 - {{formData.intereses.length}}/10</div>
                          <v-chip-group v-model="formData.intereses" column multiple max="10">
                            <v-chip filter-icon="mdi-star" active-class="deep-purple accent-4 deep-purple--text text--accent-4" v-for="(interes, index) in intereses" :key="index" filter outlined>{{interes.label}}</v-chip>
                          </v-chip-group>
                        </div>
                                    
                        <!-- <v-select
                        :items="tiposUsuarios"
                        v-model="formData.tipoUsuario"
                        label="¿Eres profesor o alumno?"
                        color="blue-grey"
                        outlined
                        ></v-select> -->
                        <v-select
                        :items="tiposAlumnos"
                        v-model="formData.tipoAlumno"
                        v-if="formData.tipoUsuario == 'Alumno'"
                        label="¿De qué curso eres?"
                        color="blue-grey"
                        outlined
                        ></v-select>

                        
                        <v-text-field
                            label="Contraseña"
                            hint="Al menos 8 caracteres"
                            :type="pass ? 'text' : 'password'"
                            :append-icon="pass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="pass = !pass"
                            counter
                            :rules="[reglas.required]"
                            v-model="formData.password"
                            outlined
                            dense
                            color="blue-grey"
                        ></v-text-field>
                        <v-input></v-input>

                        <v-btn
                            class="mr-4"
                            type="submit"
                            :disabled="!formularioValido"
                            outlined
                            color="deep-orange darken-2"
                        >
                            Registro
                        </v-btn>

                    </v-form>    
                    
                </div>
            </v-container>
        </v-responsive>
    </v-container>
</template>
<script>
export default {
  data() {
    return {
      formularioValido_registro: false,
      formularioEnviando_registro: false,
      tiposUsuarios: ["Profesor", "Alumno"],
      tiposAlumnos: ["Primero básico", "Quinto Básico", "Primero medio"],
      tipoUsuario: false,
      tipoAlumno: false,
      formularioValido: false,
      nombreCompleto: "",
      first_name: "",
      last_name: "",
      password: "",
      pass: false,
      formData: {
        hobbies: [],
        intereses: [],
      },
      reglas: {
        required: (value) => !!value || "Requerido.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo inválido.";
        },
        rut: (value) => {
          // const pattern = /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/;
          const pattern = /^\d{1,2}\d{3}\d{3}[-][0-9kK]{1}$/;
          return (
            pattern.test(value) ||
            "Rut inválido. El formato debe seguir el patrón xxxxxxxx-x"
          );
        },
      },
    };
  },
  methods: {
    submitForm() {
      this.registro({ data: this.formData });
    },
  },
  watch: {
    isUserLoggedIn(n, o) {
      if (n || o) {
        this.$router.push("/");
      }
    },
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((cpt) => {
      if (cpt.preLanzamiento) {
        next();
      } else {
        next("/");
      }
    });
  },
};
</script>
<style lang="scss">
</style>