<template>
  <div>
    <v-container>
      <div class="text-h1">Texto de título</div>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
</style>