<template lang="">
  <div>

    <div v-if="n_carreras > 0">
      
      <!-- Selectores carreras |INICIO| -->
      <div id="carreras" class="mt-4">
        <v-card
          flat
          color="grey lighten-4"
          class="text-center pa-4 pt-6 c-pointer"
          v-for="(carrera, index) in datos"
          :key="index"
          @click="carreraActivar = carrera.title"
        >

        <div v-if="ranking && index == 0" class="text-h3 font-weight-black mb-2"> 
          <font-awesome-icon
            :icon="['fad', 'trophy-alt']"
            size="1x"
            class="amber--text text--darken-3"
          ></font-awesome-icon>


        </div>
        <div v-if="ranking && index == 1" class="text-h3 font-weight-black mb-2">
          <font-awesome-icon
            :icon="['fad', 'trophy-alt']"
            size="1x"
            class="grey--text"
          ></font-awesome-icon>
        </div>
        <div v-if="ranking && index == 2" class="text-h3 font-weight-black mb-2">
          <font-awesome-icon
            :icon="['fad', 'trophy-alt']"
            size="1x"
            class="deep-orange--text text--darken-4"
          ></font-awesome-icon>
        </div>

        <!-- Número del resultado |INICIO| -->
        <div v-if="ranking" class="text-h5 font-weight-bold mb-3 blue-grey--text text--darken-2">{{index+1}}</div>
        <!-- Número del resultado |FIN| -->

          <v-img
            :src="'/carreras/' + carrera.icono"
            height="30px"
            contain
          ></v-img>

          <div class="text-center text-h6 text-uppercase text-overline">
            {{ carrera.title }}
            <!-- <v-badge
              bordered
              bottom
              :color="carrera.color"
              dot
              offset-x="0"
              offset-y="10"
            ></v-badge> -->
          </div>
          <div class="d-flex justify-center">
            <v-card
              height="2px"
              width="30px"
              :color="carrera.color"
              flat
            ></v-card>
          </div>
        </v-card>
      </div>
      <!-- Selectores carreras |FIN| -->

      <!-- Resultado carrera activa |INICIO| -->
      <v-card class="pa-7 mt-4" :color="infoCarreraActiva.color + 20" flat>


        <div v-if="ranking">
          <div class="text-h4 font-weight-bold" :style='{
            color: infoCarreraActiva.color
          }'>Tu nivel de vocación</div>
          <v-progress-linear
          :color="infoCarreraActiva.color"
          class="mb-4 mt-3"
          buffer-value="0"
          :value="(infoCarreraActiva.puntaje / 24) * 100"
          stream
          ></v-progress-linear>
        </div>

        


        <!-- Panel de seleccion -->
        <div>
          <v-btn text class="mr-2 pa-2" color="blue-grey darken-3">
            <font-awesome-icon
              :icon="['fad', 'info']"
              size="1x"
              class="mr-2"
            ></font-awesome-icon>
            Acerca
          </v-btn>
          <!-- <v-btn text class="mr-2 pa-2" color="blue-grey lighten-3">
            <font-awesome-icon
              :icon="['fad', 'user-circle']"
              size="1x"
              class="mr-2"
            ></font-awesome-icon>
            Testimonios
          </v-btn>
          <v-btn text class="mr-2 pa-2" color="blue-grey lighten-3">
            <font-awesome-icon
              :icon="['fad', 'chart-area']"
              size="1x"
              class="mr-2"
            ></font-awesome-icon>
            Empleabilidad
          </v-btn>
          <v-btn text class="mr-2 pa-2" color="blue-grey lighten-3">
            <font-awesome-icon
              :icon="['fad', 'book']"
              size="1x"
              class="mr-2"
            ></font-awesome-icon>
            Recursos
          </v-btn> -->

          <v-btn text class="mr-2 pa-2" color="blue-grey darken-3" :href="infoCarreraActiva.admision" target="blank">
            <font-awesome-icon
              :icon="['fad', 'arrow-square-right']"
              size="1x"
              class="mr-2"
            ></font-awesome-icon>
            Admisión
          </v-btn>
        </div>



        <!-- Panel de seleccion -->
        <v-divider class="my-4"></v-divider>

        <div class="d-flex">
          <div class="flex-grow-1">
            <div
              class="
                text-h4
                font-weight-bold
                blue-grey--text
                text--darken-3
                mb-5
              "
            >
              {{ infoCarreraActiva.title }}
            </div>

            <div
              v-if="typeof(infoCarreraActiva.empleabilidad) == 'number' && infoCarreraActiva.empleabilidad > 0"
              class="
                text-h6
                mb-3
              "
            >
              <span class="grey--text text--darken-1">Empleabilidad:</span> <span class="grey--text text--darken-4">{{ infoCarreraActiva.empleabilidad }} %</span> 
              <font-awesome-icon
                :icon="['fad', 'info-circle']"
                size="1x"
                class="ml-2"
                content="Porcentaje de egresados que consiguen empleo durante el primer año de egresados" v-tippy
              ></font-awesome-icon>
            </div>
            <div
              v-else
              class="
                text-h6
                mb-3
              "
            >
              No reportado
              <font-awesome-icon
                :icon="['fad', 'info-circle']"
                size="1x"
                class="ml-2"
                content="Esta carrera aún no tiene reportado el nivel de empleabilidad, debido a lo reciente de su existencia" v-tippy
              ></font-awesome-icon>
            </div>
            
            <div class="text-h5" v-html="infoCarreraActiva.desc"></div>
          </div>
          <div class="ml-10">
            <iframe
              width="560"
              height="315"
              :src="'https://www.youtube.com/embed/' + infoCarreraActiva.video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </v-card>
      <!-- Resultado carrera activa |FIN| -->
     

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carreraActivar: "",
    };
  },
  props: {
    datos: {
      type: [Object, Array],
      // Devolver con una función
      default: function () {
        return [];
      },
    },
    ranking: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {
    n_carreras() {
      return this.datos.length;
    },
    carreraActiva() {
      let res = false;
      if (this.carreraActivar !== "") {
        res = this.carreraActivar;
      }
      if (this.carreraActivar == "" && this.n_carreras > 0) {
        res = this.datos[0].title;
      }
      return res;
    },
    infoCarreraActiva() {
      let res = false;
      if (this.n_carreras > 0) {
        res = this.$props.datos.filter(
          (el) => el.title == this.carreraActiva
        )[0];
      }
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
#carreras {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>