<template lang="">
  <v-responsive content-class="py-10 d-flex flex-column justify-center blue lighten-5 blue-grey--text text--darken-4" min-height="400px">
    <div class="text-h1 font-weight-black text-center">404</div>
    <div class="text-subtitle font-weight-bold text-center">La página que solicitas no existe 😥</div>
  </v-responsive>
</template>
<script>
export default {};
</script>
<style lang="">
</style>