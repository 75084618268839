<template lang="">
  <v-container>
    <div class="text-h3">Editar perfil</div>
    <v-divider class='my-3'></v-divider>
  </v-container>
</template>
<script>
export default {};
</script>
<style lang="">
</style>