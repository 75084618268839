<template>
  <div>
    <div class="blue py-12 darken-2">
      <v-container class="white--text">
        <div class="text-h2">Test vocacional</div>
        <div class="text-subtitle-1 my-2">
          Si estás interesado en estudiar Ingeniería y aún no sabes cuál elegir,
          descubre el área de conocimiento que se ajusta más a tus gustos.
        </div>
        <div class="text-subtitle-1 my-2">
          A través de este test exploratorio, navegarás a través de diferentes
          labores que desempeñan los ingenieros en su quehacer profesional. Te
          presentaremos una serie de frases sobre las cuales tienes que
          contestar con un puntaje de 1 a 6, según tu preferencia y experiencia,
          que tanto te gustaría realizar dichas actividades en el futuro, donde
          1 significa "No me interesaría hacer eso" y 6 significa "Me encantaría
          hacer eso“.
        </div>
        <div class="text-subtitle-1 my-2">
          En función de tus resultados, te entregaremos una radar de referencias
          y te indicaremos cuáles son las tres ingenierías que podrían adecuarse
          más a tus intereses.
        </div>
      </v-container>
    </div>
    <v-container v-if="isUserLoggedIn">
      <v-divider class="my-6"></v-divider>

      <div
        v-if="!has_resultadoTestVocacional && has_estadoTestVocacional"
        id="form"
        style="width: 100%; height: 700px"
      ></div>

      <div
        v-if="!this.has_estadoTestVocacional"
        class="
          d-flex
          align-center
          blue-grey--text
          justify-space-between
          pa-10
          blue-grey
          lighten-5
          rounded
        "
      >
        <span class="text-h4"
          >Verificando en nuestro sistema si tu Test Vocacional ya fue
          realizado...
        </span>
        <font-awesome-icon
          :icon="['fad', 'alien-monster']"
          class="ml-4"
          size="2x"
          spin
        />
      </div>

      <div
        v-if="has_estadoTestVocacional && has_resultadoTestVocacional"
        class="
          d-flex
          justify-space-between
          align-center
          green--text
          text--accent-3
          pa-10
          green
          lighten-5
          rounded
        "
      >
        <div>
          <div class="text-h4">Ya realizaste tu Test Vocacional</div>
          <v-btn
            color="green accent-4"
            outlined
            class="mt-4"
            to="/resultado-test-vocacional"
            >Revisar resultado
            <font-awesome-icon :icon="['fad', 'poll-h']" class="ml-2" size="1x"
          /></v-btn>
        </div>
        <font-awesome-icon :icon="['fad', 'check']" class="ml-4" size="2x" />
      </div>
    </v-container>
    <v-container
      v-if="verificandoSesion"
      class="
        text-h4
        my-5
        grey
        lighten-3
        blue-grey--text
        text--darken-2
        pa-10
        rounded
      "
    >
      Verificando tu sesión
    </v-container>
    <v-container
      v-if="!verificandoSesion && !isUserLoggedIn"
      class="
        text-h4
        my-5
        grey
        lighten-3
        blue-grey--text
        text--darken-2
        pa-10
        rounded
      "
    >
      Sección restringida para usuarios registrados
      <br />
      <div class="mx-n2 my-4">
        <v-btn outlined to="/registro" color="deep-orange" class="mx-2"
          >Registro</v-btn
        >
        <v-btn outlined to="/iniciar-sesion" color="blue" class="mx-2"
          >Iniciar sesión</v-btn
        >
      </div>
    </v-container>
  </div>
</template>
<script>
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";

export default {
  watch: {
    has_estadoTestVocacional(n) {
      if (n) {
        if (!this.has_resultadoTestVocacional) {
          setTimeout(() => {
            this.montarTypeform();
          }, 300);
        }
      }
    },
  },
  methods: {
    montarTypeform() {
      createWidget("ZWfEmfCQ", {
        container: document.querySelector("#form"),
        onSubmit: () => {
          this.consultarRespuestaTest();
          this.$router.push("/resultado-test-vocacional");
        },
      });
    },
  },
  mounted() {
    if (!this.has_resultadoTestVocacional && this.has_estadoTestVocacional) {
      this.montarTypeform();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((cpt) => {
      if (cpt.preLanzamiento) {
        if (!cpt.has_resultadoTestVocacional) {
          next();
        } else {
          next("/resultado-test-vocacional");
        }
      }
      if (!cpt.preLanzamiento) {
        next("/");
      }
    });
  },
};
</script>
<style lang="scss">
</style>