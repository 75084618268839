<template lang="">
    <v-container>
       <v-responsive :aspect-ratio='10/8' v-on:submit.prevent="submitForm">
            <v-container class="pa-10 my-10 rounded-lg grey lighten-4" id="form-content">
                <div class='text-h2 font-weight-bold'>Inicia Sesión</div>
                <v-divider class="my-5"></v-divider>
                <div >
                  <div v-for="(mensaje, indexmensaje) in mensajesFormulario" 
                    :key="indexmensaje" >
                      <v-alert 
                      v-if="mensaje.estado" 
                      :type="mensaje.type">
                          {{mensaje.text}}
                      </v-alert>
                  </div>
                   
                    <v-form v-model="formularioValido" class="py-5">
                        
                        <v-text-field
                            label="Correo electrónico"
                            outlined
                            dense
                            name="usuario"
                            v-model="formData.usuario"
                            :rules="[reglas.required, reglas.email]"
                            color="blue-grey"
                        ></v-text-field>
                        <v-text-field
                            label="Contraseña"
                            hint="Al menos 8 caracteres"
                            :type="pass ? 'text' : 'password'"
                            :append-icon="pass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="pass = !pass"
                            counter
                            :rules="[reglas.required]"
                            v-model="formData.password"
                            outlined
                            dense
                            color="blue-grey"
                        ></v-text-field>

                        <v-btn
                            class="mr-4"
                            type="submit"
                            :disabled="!formularioValido"
                            outlined
                            color="deep-orange darken-2"
                        >
                            Iniciar sesión
                        </v-btn>

                    </v-form>    
                    
                </div>
            </v-container>
        </v-responsive>
    </v-container>
</template>
<script>
export default {
  data() {
    return {
      formularioValido_iniciarSesion: false,
      formularioEnviando_iniciarSesion: false,
      formularioValido: false,
      pass: false,
      formData: {},
      reglas: {
        required: (value) => !!value || "Requerido.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo inválido.";
        },
      },
    };
  },
  watch: {
    isUserLoggedIn(n, o) {
      if (n || o) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    submitForm() {
      this.iniciarSesion({ data: this.formData });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((cpt) => {
      if (cpt.preLanzamiento) {
        next();
      } else {
        next("/");
      }
    });
  },
};
</script>
<style lang="scss">
</style>