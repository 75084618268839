<template>
  <div>
    <v-img
      min-height="600px"
      content-class="d-flex align-center py-5 blue lighten-5"
    >
      <v-container class="d-flex">
        <div>
          <div class="text-h1">Explora tu vocación</div>
          <div>
            <div>
              <span
                >Lorem ipsum dolor sit amet consectetur adipiscing elit mi
                ligula urna aptent, lectus himenaeos pellentesque tortor magnis
                eros egestas dapibus cursus donec curae pharetra, ante commodo
                aliquam nulla hendrerit torquent per proin cras justo.
              </span>
              <br />
              <span
                >¡Descubre que ingeniería se adapta más a tus intereses!</span
              >
            </div>
          </div>
        </div>
        <v-responsive
          class="
            grey
            lighten-3
            rounded-lg
            d-flex
            align-center
            text-center text-overline
          "
          min-width="400px"
          :aspect-ratio="2"
          >Video vocacional</v-responsive
        >
      </v-container>
    </v-img>
    <!--  -->
    <v-container class="d-flex justify-center py-10">
      <v-responsive max-width="500px" content-class="text-center"
        >Lorem ipsum dolor sit amet consectetur adipiscing elit mi ligula urna
        aptent, lectus himenaeos pellentesque tortor magnis eros egestas dapibus
        cursus donec curae pharetra, ante commodo aliquam nulla hendrerit
        torquent per proin cras justo.
        <br />
        <v-btn
          class="my-4"
          color="blue"
          dark
          elevation="0"
          outlined
          to="/test-vocacional"
          >Quiero hacer el test
          <font-awesome-icon :icon="['fad', 'thumbs-up']" class="ml-4"
        /></v-btn>
      </v-responsive>
    </v-container>
    <!--  -->
    <!--  -->
    <v-container class="py-10">
      <div class="text-h2">Catálogo de carreras</div>
      <div class="text-subtitle-1">
        Explora las carreras de la Facultad de Ingeniería
      </div>

      <carreras :datos="carreras"></carreras>
    </v-container>
    <!--  -->
  </div>
</template>
<script>
import Carreras from "../../components/info/Carreras";
export default {
  components: { Carreras },
  data() {
    return {
      carreraActiva: "Plan común",
    };
  },
  computed: {
    infoCarreraActiva() {
      return this.carreras.filter((el) => el.title == this.carreraActiva)[0];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((cpt) => {
      if (cpt.lanzamiento) {
        next();
      } else {
        next("/");
      }
    });
  },
};
</script>
<style lang="scss" scoped>
#carreras {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>