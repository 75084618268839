<template lang="">
  <div>

    <div v-if="!isUserLoggedIn">
      Verificando tu sesión
    </div>

    <v-container v-if="isUserLoggedIn && has_datosUsuario">
      <div class="font-weight-bold grey--text text--lighten-1">¡Hola! 👋</div>
      <div class="text-h3">{{datosUsuario.data.display_name}}</div>
      <v-divider class="my-3"></v-divider>
      
      <div class="d-flex mx-n4">
        <v-card color="mx-4 col-4 grey lighten-5" elevation="0" outlined class="pa-3">
          <div class="text-overline">Datos generales</div>
        
          <div class="text-body-1 font-weight-bold">Rut</div>
          <div class="text-body-2">{{datosUsuario.data.meta['wpcf-rut']}}</div>
          <div class="text-body-1 font-weight-bold">Correo</div>
          <div class="text-body-2">{{datosUsuario.data.user_email}}</div>
          <div class="mt-3">
            <!-- <v-btn outlined small to="/editar-perfil">Editar perfil</v-btn> -->
          </div>
        </v-card>
        <div class="mx-4 col-8 pa-3">
          <div class="text-overline">Hobbies e Intereses Cotidianos</div>
          <div class="ma-n1">
            <v-chip color="red accent-3" class="ma-1" outlined v-for="(hobbie, index) in hobbiesUsuario" :key="index">{{hobbie.label}}</v-chip>
          </div>
          <div class="text-overline mt-4">Intereses tecnológicos</div>
          <div class="ma-n1">
            <v-chip color="deep-purple accent-4" class="ma-1" outlined v-for="(interes, index) in interesesUsuario" :key="index">{{interes.label}}</v-chip>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  computed: {},
};
</script>
<style lang="">
</style>