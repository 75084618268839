<template lang="">
  <div>
  
  
    <div class="blue py-12 darken-2" v-if="has_resultadoLoadedTestVocacional">
      <v-container class="white--text">
        <div class="text-h2">Tus resultados</div>
        <div class="text-subtitle-1 my-2">En función de tus respuestas podemos generar un perfil de tus preferencias respecto a las carreras que ofrecemos como Facultad de Ingeniería UdeC!</div>
        <div class="text-subtitle-1 my-2">Recuerda que la información que te entregaremos a continuación debe ser usada como punto de referencia para una investigación más profunda sobre la elección de tu carrera, pues debes considerar otros factores en tu decisión vocacional.</div>
      </v-container>
    </div>
  
  
    <v-container>
      <chart-bar v-if="false"></chart-bar>
  
  
  
      <div v-if="has_resultadoLoadedTestVocacional">
  
  
        <div class="text-subtitle-1 mb-5 py-5">El siguiente radar permite explorar e interpretar tus preferencias vocacionales asociadas a las carreras de Ingeniería que impartimos en la Facultad. Es útil para analizar visualmente por cuales áreas tienes más preferencia.</div>
  
  
        <!-- <div class="text-h3 font-weight-medium text-center my-10 blue-grey--text text--darken-4">Resultado test vocacional</div> -->
        <v-responsive height="700px">
          <chart-radar style="height:700px;"
          :options="opciones"
          :chartData="datosRadar">
          </chart-radar>
  
        </v-responsive>
        <v-divider class="my-3 mt-10"></v-divider>
        <carreras :datos="resultadoCarreras.data" ranking></carreras>
      </div>
  
  
  
      <div v-if="isUserLoggedIn">
        <!-- has_estadoTestVocacional: {{has_estadoTestVocacional}}
        <br>
        has_resultadoLoadedTestVocacional: {{has_resultadoLoadedTestVocacional}}
        <br>
        has_resultadoTestVocacional: {{has_resultadoTestVocacional}}
        <br> -->
        <!-- Resultado test data |INICIO| -->
        <div v-if="has_resultadoLoadedTestVocacional">
          <!-- <div v-if="has_resultadoTestVocacional">Acá va el resultado</div>
          <pre>{{resultadoCarreras}}</pre> -->
        </div>
        <!-- Resultado test data |FIN| -->
  
        <!-- Resultado test data |INICIO| -->
        <div v-if="consultando_test">
            <div
            class="
              d-flex
              align-center
              blue-grey--text
              justify-space-between
              pa-10
              blue-grey
              lighten-5
              rounded
            "
            >
              <span class="text-h4"
                >Verificando en nuestro sistema si tu Test Vocacional ya fue
                realizado...
              </span>
              <font-awesome-icon
                :icon="['fad', 'alien-monster']"
                class="ml-4"
                size="2x"
                spin
              />
            </div>
        </div>
        <!-- Resultado test data |FIN| -->
  
        <!-- Resultado test data |INICIO| -->
        <div v-if="has_estadoTestVocacional && !has_resultadoLoadedTestVocacional && !has_resultadoTestVocacional">
          <div
            class="
              d-flex
              align-center
              blue-grey--text
              justify-space-between
              pa-10
              blue-grey
              lighten-5
              rounded
            "
            >
              <div>
                <span class="text-h4"
                  >No posees un test vocacional
                </span>
                <br>
                <v-btn color="orange" outlined class="my-4" large to="/test-vocacional">Realizar test vocacional 🚀</v-btn>
  
              </div>
              <font-awesome-icon
                :icon="['fad', 'window-close']"
                class="ml-4"
                size="2x"
              />
            </div>
        </div>
        <!-- Resultado test data |FIN| -->
  
  
  
      </div>
  
      <!-- NO SESIÓN |INICIO| -->
      <div v-else>
        <div class="text-h5">Esta sección está restringida para usuarios registrados</div>
        <v-btn color="orange" outlined class="my-4" large to="/registro">Registro</v-btn>
      </div>
      <!-- NO SESIÓN |FIN| -->
  
  
    </v-container>
</div>
</template>
<script>
import ChartBar from "../../components/ui/ChartBar";
import ChartRadar from "../../components/ui/ChartRadar";
import Carreras from "../../components/info/Carreras";

export default {
  data() {
    return {
      opciones: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scale: {
          ticks: {
            beginAtZero: true,
            max: 24,
            min: 0,
            stepSize: 6,
          },
        },
        elements: {
          line: {
            borderWidth: 3,
          },
        },
      },
    };
  },
  computed: {
    datosRadar() {
      let res = {};
      if (this.has_resultadoLoadedTestVocacional) {
        res = {
          labels: this.resultadoCarreras.labels,
          datasets: [
            {
              label: "Puntaje individual",
              data: this.resultadoCarreras.puntajes,
              fill: true,
              backgroundColor: "#00B0FF30",
              borderColor: "#00B0FF",
              pointBackgroundColor: "#00B0FF",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "#00B0FF",
            },
            // {
            //   label: "My Second Dataset",
            //   data: [28, 48, 40, 19, 96, 27, 100],
            //   fill: true,
            //   backgroundColor: "rgba(54, 162, 235, 0.2)",
            //   borderColor: "rgb(54, 162, 235)",
            //   pointBackgroundColor: "rgb(54, 162, 235)",
            //   pointBorderColor: "#fff",
            //   pointHoverBackgroundColor: "#fff",
            //   pointHoverBorderColor: "rgb(54, 162, 235)",
            // },
          ],
        };
      }

      return res;
    },
  },
  components: { ChartBar, ChartRadar, Carreras },
};
</script>
<style lang="">
</style>