<template lang="">
    <v-container>
      <div class="text-h1">Cursos y talleres</div>
      <v-divider class="my-4"></v-divider>
      
      <div id="niveles" class="mx-n1 mb-3">
        <v-btn v-for="(categoria, index) in categorias" :key="index" outlined :color="(categoria.slug == categoriaActiva) ? 'blue' : 'grey'" class="ma-1" @click="categoriaActiva = categoria.slug">{{categoria.title}}</v-btn>
      </div>

      <div id="grilla-cursos">
        <v-card v-for="(curso, index) in cursos" :key="index" flat outlined class="d-flex flex-column justify-lg-space-between">
          <div>
            <v-img height="300px" :src="'https://picsum.photos/id/'+curso.id+'/200/300?grayscale&blur=2'"></v-img>
            <v-card-title class="text-h6 font-weight-bold blue-grey--text">{{curso.title}}</v-card-title>
            <v-card-subtitle>{{curso.desc}}</v-card-subtitle>
          </div>
          
          <v-card-actions>
            <v-btn elevation="0" outlined color="blue" small>Realizar curso</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
</template>
<script>
export default {
  data() {
    return {
      categoriaActiva: "nivelacion",
      categorias: [
        {
          title: "Nivelación",
          slug: "nivelacion",
        },
        {
          title: "Inglés",
          slug: "ingles",
        },
        {
          title: "Apoyo psicoeducativo",
          slug: "apoyo-psicoeducativo",
        },
        {
          title: "Innovación",
          slug: "innovacion",
        },
      ],
      cursos: [
        {
          id: 25,
          title: "Matemática general",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum vel dolor at maximus",
          categoria: ["nivelacion"],
        },
        {
          id: 27,
          title: "Física",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum vel dolor at maximus",
          categoria: ["nivelacion"],
        },
        {
          id: 28,
          title: "Química",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rutrum vel dolor at maximus",
          categoria: ["nivelacion"],
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((cpt) => {
      if (cpt.lanzamiento) {
        next();
      } else {
        next("/");
      }
    });
  },
};
</script> 
<style lang="scss" scoped>
#grilla-cursos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}
</style>