<script>
// CommitChart.js
import { Radar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    // this.chartData is created in the mixin.
    this.renderChart(this.chartData, this.options);
  },
  // If you want to pass options please create a local options object
  watch: {
    chartData() {
      this.$data._chart.update();
    },
  },
};

// import { Line } from "vue-chartjs";

// export default {
//   extends: Line,
//   props: {
//     chartdata: {
//       type: Object,
//       default: null,
//     },
//     options: {
//       type: Object,
//       default: null,
//     },
//   },
//   mounted() {
//     this.renderChart(this.chartdata, this.options);
//   },
// };
</script>